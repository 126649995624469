<template>
  <Suspense>
    <template #default>
      <HeaderNavigation />
    </template>
    <template #fallback>
      <div class="loading-container">
        <div class="spinner"></div>
        <p>Loading, please wait...</p>
      </div>
    </template>
  </Suspense>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  name: "App",
  components: {
    HeaderNavigation: defineAsyncComponent(() =>
      import("./components/HeaderNavigation.vue")
    ),
  },
};
</script>

<style scoped>
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full height of the viewport */
  background-color: #f8f9fa; /* Light background color */
  color: #343a40; /* Dark text color */
  font-size: 1.5rem; /* Larger font size */
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1); /* Light border */
  border-left-color: #b6300f; /* Primary color for the spinner */
  border-radius: 50%;
  width: 50px; /* Spinner size */
  height: 50px; /* Spinner size */
  animation: spin 1s linear infinite; /* Spin animation */
  margin-bottom: 20px; /* Space between spinner and text */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
