import {decryptData} from '@/utils/crypto'

export function isTokenExpired(expiresIn) {
    if (!expiresIn || typeof expiresIn !== 'number') return true;

    const currentTime = Math.floor(new Date().getTime() / 1000); // Current time in seconds
    checkTokenExpiration(expiresIn);
    return expiresIn < currentTime;
}

const checkTokenExpiration = (expiresIn) => {
  const currentTime = Math.floor(new Date().getTime() / 1000); // Current time in seconds since Unix epoch
  const timeDifference = expiresIn - currentTime; // Difference in seconds
  const minutesLeft = Math.floor(timeDifference / 60); // Convert seconds to minutes

  //console.log('Token expires in:', minutesLeft, 'minutes');

  // Return the minutes left
  return minutesLeft;
};
export function isAuthenticated() {
    const token = localStorage.getItem('user'); // Example: Check if a token exists in localStorage
    return !!token; // Return true if token exists, false otherwise
  }

  export function getUserRoles() {
    try {
      // Attempt to retrieve and decrypt the data from localStorage
      const userData = localStorage.getItem('user');
      
      // If there's no user data, return an empty array
      if (!userData) return [];
  
      // Decrypt and parse the JSON safely
      const decryptedData = decryptData(userData);
      const rolesString = JSON.parse(decryptedData).roles;
  
      // Return roles if found, otherwise return an empty array
      return rolesString ? rolesString : [];
    } catch (error) {
      console.error('Error retrieving user roles:', error);
      return []; // Return empty array in case of an error
    }
  }
  

  export function hasRoles(requiredRoles){
    return requiredRoles.some((role) => getUserRoles().includes(role));
  }