import { createRouter, createWebHistory } from 'vue-router';
import { getUserRoles, isAuthenticated } from '@/utils/auth';
import { roles } from '@/utils/config';
import store from '@/store';
import spinnerService from '@/services/spinnerService';
import { nextTick } from 'vue';

// Use dynamic imports for async loading
const DashboardPage = () => import('@/pages/DashboardPage.vue');
const VotersPage = () => import('@/pages/voters/VotersPage.vue');
const LoginPage = () => import('@/pages/user/LoginPage.vue');
const NotFound = () => import('@/pages/NotFound.vue');
const HouseholdsPage = () => import('@/pages/households/HouseholdsPage.vue');
const ForbiddenPage = () => import('@/pages/ForbiddenPage.vue');
const HeatMapPage = () => import('@/pages/maps/HeatMapPage.vue');
const HeatMapSetupPage = () => import('@/pages/maps/HeatMapSetupPage.vue');
const BarangaySettings = () => import('@/pages/areas/BarangaySettings.vue');
const CitySettings = () => import('@/pages/areas/CitySettings.vue');
const UserSetup = () => import('@/pages/user/UserSetup.vue');
const IDCardPrintingPage = () => import('@/pages/printing/IDCardPrintingPage.vue');
const EventsPage = () => import('@/pages/logs/EventsPage.vue');
const ImportPage = () => import('@/pages/voters/ImportPage.vue');
const ActionsPage = () => import('@/pages/actions/ActionsPage.vue');
const UserLogsPage = () => import('@/pages/user/UserLogsPage.vue');
const ActivationLogsPage = () => import('@/pages/user/ActivationLogsPage.vue');
const VotersMaritess = () => import('@/pages/voters/VotersMaritess.vue');
const LogDetailPage = () => import('@/pages/voters/LogDetailPage.vue');
const SitioMerger = () => import('@/pages/tools/SitioMerger.vue');
const SystemLogs = () => import('@/pages/logs/SystemLogs.vue');
const UserMapPage = () => import('@/pages/maps/UserMapPage.vue');
const GTAccomplishmentsPage = () => import('@/pages/user/GTAccomplishmentsPage.vue');
const BillingPage = () => import('@/pages/user/BillingPage.vue');
const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: DashboardPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminDashboardRoles }
    },
    {
        path: '/voters',
        name: 'voters',
        component: VotersPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminVotersRoles }
    },
    {
        path: '/sitio-merger',
        name: 'sitio-merger',
        component: SitioMerger,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'tools-sitio-merger'] }
    },
    {
        path: '/households',
        name: 'households',
        component: HouseholdsPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminHouseholdRoles }
    },
    {
        path: '/heatmap',
        name: 'heatmap',
        component: HeatMapPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminHeatMapRoles }
    },
    {
        path: '/usermap',
        name: 'usermap',
        component: UserMapPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin','web-recent-online-users'] }
    },
    {
        path: '/map-setup',
        name: 'map-setup',
        component: HeatMapSetupPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminHeatMapSetup }
    },
    {
        path: '/brgy-setup',
        name: 'brgy-setup',
        component: BarangaySettings,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminBarangaySettings }
    },
    {
        path: '/city-setup',
        name: 'city-setup',
        component: CitySettings,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminCitySettings }
    },
    {
        path: '/user-setup',
        name: 'user-setup',
        component: UserSetup,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminUserSettings }
    },
    {
        path: '/user-actions',
        name: 'user-actions',
        component: ActionsPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'user-actions'] }
    },
    {
        path: '/print-id',
        name: 'print-id',
        component: IDCardPrintingPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminPrint }
    },
    {
        path: '/voter-logs',
        name: 'voter-logs',
        component: EventsPage,
        meta: { requiresAuth: true, showHeader: true, roles: roles.adminLogs }
    },
    {
        path: '/voter-maritess',
        name: 'voter-maritess',
        component: VotersMaritess,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'web-voter-maritess'] }
    },
    {
        path: '/user-logs',
        name: 'user-logs',
        component: UserLogsPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'web-user-logs'] },
    },
    {
        path: '/tagger-reports',
        name: 'tagger-reports',
        component: GTAccomplishmentsPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'web-geotagger-reports'] },
    },
    {
        path: '/tagger-billing',
        name: 'tagger-billing',
        component: BillingPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'web-geotagger-billing'] },
    },
    {
        path: '/log-details/:id',
        name: 'log-details',
        component: LogDetailPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'web-user-logs'] },
        props: true

    },
    {
        path: '/user-verification',
        name: 'user-verification',
        component: ActivationLogsPage,
        meta: { requiresAuth: true, showHeader: true, roles: ['admin', 'user-verification'] }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage,
        meta: { showHeader: false }
    },
    {
        path: '/imports',
        name: 'imports',
        component: ImportPage,
        meta: { showHeader: true, requiresAuth: true, roles: roles.adminImport }
    },
    {
        path: '/system-logs',
        name: 'system-logs',
        component: SystemLogs,
        meta: { showHeader: true, requiresAuth: true, roles: ['admin'] }
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: ForbiddenPage,
        meta: { showHeader: false }
    },
    { path: '/:catchAll(.*)', component: NotFound, meta: { showHeader: false } }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { el: to.hash };
        }
        return savedPosition || { top: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
    spinnerService.show();
    // Check if the route requires authentication and the user is authenticated
    if (to.meta.requiresAuth && !isAuthenticated()) {
        // Redirect to login page with return URL as query parameter
        return next({
            path: '/login',
            query: { return_url: to.fullPath }
        });
    }

    // Handle role-based access control
    if (to.meta.roles && to.meta.roles.length > 0) {
        const userRoles = getUserRoles(); // Example function to get user roles
        const allowedRoles = to.meta.roles;

        // Check if any of the user's roles match the allowed roles
        const hasAccess = userRoles.some(role => allowedRoles.includes(role));

        if (!hasAccess) {
            // Redirect to unauthorized page or handle unauthorized access
            return next('/forbidden'); // Example redirect to unauthorized page
        }
    }

    // Update header visibility based on the route meta
    const showHeader = to.meta.showHeader !== false; // Default to true if not explicitly set
    await store.dispatch("auth/showAdminHeader", showHeader);
    await nextTick();
    // Continue to the route
    next();
});
router.afterEach(() => {
    spinnerService.hide(); // Hide spinner
  });

export default router;
